import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';
import landingPage from '../images/under-construction.png';

function Index() {
  return (
    <main>
      <Helmet>
        <title>Maple Slice Enabler</title>
      </Helmet>
      <img src={landingPage} alt="Under Construction" />
    </main>
  );
}

export default Index;
